import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
// import 'ag-grid-enterprise';
import { AgGridReact } from "ag-grid-react";
import { setLeadsId } from "features/Leads/leadSlice";
import { selectLeads } from "features/Leads/leadSlice";
import { selectLocations } from "features/locations/locationSlice";
import { selectUsers } from "features/users/userSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "services/api";
import { Button } from "../button";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerOverlay,
  DrawerTitle,
} from "../drawer";
import { IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import LeadEdit from "./LeadEdit";
import dayjs from "dayjs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../dropdown-menu";

var checkboxSelection = function (params) {
  // we put checkbox on the name if we are not doing grouping
  return params.api.getRowGroupColumns().length === 0;
};

var headerCheckboxSelection = function (params) {
  // we put checkbox on the name if we are not doing grouping
  return params.api.getRowGroupColumns().length === 0;
};

const LeadsGridTable = () => {
  const leads = useSelector(selectLeads);
  const locations = useSelector(selectLocations);
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [selectedRowData, setSelectedRowData] = useState(null); // State to hold selected row data
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [subLocations, setSubLocations] = useState([]);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const user = JSON.parse(localStorage.getItem("user"));

  const [userDropdownVisible, setUserDropdownVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const handleTypeSelect = (type, params) => {
    setSelectedType(type);
    params.api.setFilterModel({
      [params.column.colDef.field]: {
        filterType: "text",
        type: "contains",
        filter: type,
      },
    });
    params.api.onFilterChanged();
  };

  const customTypeHeaderComponent = (params) => {
    return (
      <div className="flex items-center relative">
        <span  className="capitalize">{params.column.colDef.field}</span>
        <select
          // value={selectedType}
          onChange={(e) => handleTypeSelect(e.target.value, params)}
          className="ml-2"
        >
          <option value="">All</option>
          <option value="Lease">Lease</option>
          <option value="Resale">Resale</option>
        </select>
      </div>
    );
  };

  const handleUserSelect = (userId, params) => {
    console.log("Selected User ID:", userId); // Debugging line
    setSelectedUserId(userId);

    const filterModel = {
      [params.column.colDef.field]: {
        filterType: "text",
        type: "contains",
        filter: userId,
      },
    };

    console.log("Setting Filter Model:", filterModel); // Debugging line
    params.api.setFilterModel(filterModel);
    params.api.onFilterChanged(); // Trigger filter change
    setUserDropdownVisible(false); // Close dropdown after selection
  };
  console.log("customer check", userDropdownVisible);
  const customHeaderComponent = (params) => {
    console.log("params of customerheader", params);
    return (
      <div className="flex relative items-center">
        <span className="capitalize">{params.column.colDef.field}</span>
        
        <select
          onChange={(event) => {
            console.log("we are calling");
            handleUserSelect(event.target.value, params);
          }}
        >
          <option value="">All</option>
          {users?.map((user) => (
            <option value={user.name} key={user._id}>
              {user.name}
            </option>
          ))}
        </select>
        {/* {userDropdownVisible && ( */}
        {/* <>
          <p>wonderfull</p>

          <DropdownMenu className="absolute bg-black w-10 h-10 top-0 z-[998899]">
            <DropdownMenuContent>
              {users?.map((user) => (
                <DropdownMenuItem
                  key={user._id}
                  onClick={() => handleUserSelect(user._id, params)}
                >
                  {user.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </> */}
        {/* )} */}
      </div>
    );
  };

  const handleCheckboxSelection = (params) => {
    const selectedIds = params.api.getSelectedRows()?.map((row) => row._id);
    dispatch(setLeadsId(selectedIds));
  };
  const onRowClicked = (params) => {
    const selectedIds = params.api;
    console.log(selectedIds, "selectedIds");
  };
  const handleOpenModal = (event) => {
    setSelectedRowData(event.data);
    setIsEditModalOpen(true);
    // dispatch(modalOpened())
  };
  const handleClose = () => {
    setIsEditModalOpen(false);
    // dispatch(modalClosed());
  };
  const handleFileClick = (data) => {
    setSelectedFiles(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "",
      minWidth: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      // headerCheckboxSelectionFilteredOnly: true,
      editable: false,
      onCellValueChanged: (event) => {
        setSelectedRowData(event.data);
        dispatch(setLeadsId(event.data));
      },
    },
    {
      field: "userid",
      minWidth: 170,
      headerName: "User ID",
      // headerComponent: customHeaderComponent, // Use custom header component
      filter: true,
      // filterParams: {
      //   values: (params) => {
      //     const uniqueUsers = [...new Set(users?.map((user) => user.name))];
      //     params.success(uniqueUsers);
      //   },
      // },
      // valueGetter: (params) => {
      //   const user = users.find((u) => u._id === params.data.userid);
      //   return user ? user.name : ""; // Return user name for display
      // },
      // valueSetter: (params) => {
      //   const user = users.find((u) => u.name === params.newValue);
      //   if (user) {
      //     params.data.userid = user.userid; // Set the user ID
      //     return true;
      //   }
      //   return false;
      // },
      cellRenderer: (params) => {
        const user = users.find((u) => parseInt(u.userid) === (params.data.userid));
        console.log(params, "params userid",user,users);
        return <div>{user ? user.name : ""}</div>; // Display user name
      },
    },
    {
      field: "type",
      minWidth: 140,
      editable: true,
      headerComponent: customTypeHeaderComponent, // Use custom header component
    },
    {
      field: "",
      editable: false,
      cellRenderer: (params) => {
        console.log(params, "params");
        return (
          // <div onClick={onRowClicked}>
          <Button onClick={() => handleOpenModal(params)}>Edit</Button>
          // </div>
        );
      },
    },
    {
      field: "assignTo",
      minWidth: 570,
      headerComponent: customHeaderComponent, // Use custom header component
      filter: true,
      filterParams: {
        values: (params) => {
          const uniqueUsers = [...new Set(users?.map((user) => user.name))];
          params.success(uniqueUsers);
        },
      },
      valueGetter: (params) => {
        const user = users.find((u) => u._id === params.data.assignTo);
        return user ? user.name : ""; // Return user name for display
      },
      valueSetter: (params) => {
        const user = users.find((u) => u.name === params.newValue);
        if (user) {
          params.data.assignTo = user._id; // Set the user ID
          return true;
        }
        return false;
      },
      cellRenderer: (params) => {
        const user = users.find((u) => u._id === params.data.assignTo);
        return <div>{user ? user.name : ""}</div>; // Display user name
      },
    },
    {
      field: "category",
      minWidth: 170,
      editable: true,
    },

    {
      field: "name",
      minWidth: 170,
      editable: true,
    },
    {
      field: "contactNo",
      minWidth: 170,
      editable: true,
    },

    // {
    //   field: "userId.name",
    //   minWidth: 170,
    //   editable: true,
    // },

    {
      field: "budget",
      minWidth: 170,
      editable: true,
    },

    {
      field: "location",
      minWidth: 170,
      editable: true,
      cellRenderer: (params) => {
        const location = locations.find(
          (loc) => loc._id === params.data.location
        );

        return (
          <div>
            <h2>{location?.locationName}</h2>
          </div>
        );
      },
    },
    {
      field: "subLocation",
      minWidth: 170,
      editable: true,
      cellRenderer: (params) => {
        console.log(params, "this is params");
        const subLocation = subLocations.find(
          (loc) => loc._id === params.data.subLocation
        );

        if (subLocation) {
          console.log(subLocation, params, "this is subLocation");
        }
        return (
          <div>
            <h2>{subLocation?.subLocationName}</h2>
          </div>
        );
      },
    },

    {
      field: "lookingarea",
      headerName: "Looking For",
      minWidth: 170,
      editable: true,
    },
    {
      field: "nxtreminder",
      headerName: "Next Reminder",
      minWidth: 170,
      editable: true,
      cellRenderer: (params) => {
        const dateValue = params.value
          ? dayjs(params.value)
          : dayjs("1900-01-01");
        return dateValue.isValid() ? dateValue.format("DD/MM/YY") : "";
      },
    },

    {
      field: "interestedBeforeDate",
      minWidth: 170,
      editable: true,
      cellRenderer: (params) => {
        // Format date using dayjs
        return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "";
      },
    },
    // {
    //   field: "action",
    //   minWidth: 170,
    //   editable: true,
    // },
    {
      field: "remark",
      minWidth: 170,
      editable: true,
    },

    {
      field: "createBy",
      minWidth: 270,
      editable: false,
    },
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Group",
      minWidth: 170,
      field: "athlete",
      valueGetter: (params) => {
        if (params.node.group) {
          return params.node.key;
        } else {
          return params.data[params.colDef.field];
        }
      },
      headerCheckboxSelection: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const fetchSubLocations = async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/subLocation/"
          : `api/subLocation/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed deals");
    }
  };
  useEffect(() => {
    fetchSubLocations().then((data) => {
      setSubLocations(data);
    });
  }, []);

  const onGridReady = useCallback(async (params) => {
    console.log("Grid is Ready");
  }, []);

  return (
    <div style={containerStyle} className="custom-scrollbar">
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact
          rowData={leads}
          columnDefs={columnDefs}
          // autoGroupColumnDef={autoGroupColumnDef}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          groupSelectsChildren={true}
          rowSelection={"multiple"}
          editType={"fullRow"}
          rowGroupPanelShow={"always"}
          pivotPanelShow={"always"}
          pagination={true}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
          onSelectionChanged={handleCheckboxSelection}
        />
        {userDropdownVisible && (
          <DropdownMenu>
            <DropdownMenuContent>
              {users.map((user) => (
                <DropdownMenuItem
                  key={user._id}
                  onClick={() => handleUserSelect(user._id)}
                >
                  {user.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      <Drawer
        open={isEditModalOpen}
        direction="right"
        className="overflow-scroll"
      >
        <DrawerOverlay onClick={handleClose} />
        {/* <DrawerTrigger asChild>
        <Button
          onClick={handleOpen}
          variant="outline"
          className="bg-blue-700 text-white"
        >
          <BiPlus /> Add Deal
        </Button>
      </DrawerTrigger> */}
        <DrawerContent className="right-0 z-50 left-auto h-full w-[45rem] overflow-auto">
          <div className="mx-auto w-full">
            <IconButton
              className="float-end"
              onClick={handleClose}
              icon={<CloseIcon />}
            />
            <DrawerHeader className="text-left">
              <DrawerTitle>Edit Lead</DrawerTitle>
              <DrawerDescription>
                Edit a Lead. Click save when you're done.
              </DrawerDescription>
            </DrawerHeader>
            <LeadEdit
              className="px-4"
              leadRowData={selectedRowData}
              setIsModalOpen={setIsEditModalOpen}
            />
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default LeadsGridTable;
