import * as React from "react";
// import { Bar, BarChart, ResponsiveContainer } from "recharts"

import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { Form, useFormik } from "formik";
import { getApi, putApi } from "services/api";
import { cn } from "lib/utils.ts";
import { useState, useEffect } from "react";
import { postApi } from "services/api";
import * as Yup from "yup";
import { Button } from "../button";
import { Calendar } from "../calendar";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerTitle,
  DrawerTrigger,
} from "../drawer";
import { Input } from "../input";
import { Label } from "../label";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { Switch } from "../switch";
import {
  modalClosed,
  modalData,
} from "../../../redux/slices/editModalSlice/editModalSlice.js";
import { modalOpened } from "../../../redux/slices/editModalSlice/editModalSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { BiPlus } from "react-icons/bi";
import { CloseIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { selectDeals } from "features/deals/dealSlice";
import { toast } from "react-toastify";
import { selectLeads } from "features/Leads/leadSlice";
import { selectLocations } from "features/locations/locationSlice";
import { fetchLeadsAsync } from "features/Leads/leadSlice";


function ProfileForm({ className }) {
  const [isLoding, setIsLoding] = React.useState(false);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [subLocations, setSubLocations] = useState([]);

  const leads = useSelector(selectLeads);
  const locations = useSelector(selectLocations);





const initialValues = {
  name: "",
  type: "",
  userId: "",
  assignTo: "",
  category: "",
  contactNo: "",
  budget: "",
  location: "",
  lookingarea: "",
  nxtreminder: "",
  subLocation: "",
  lookingFor: "",
  interestedBeforeDate: "",
  action: "",
  remark: "",
  createBy: JSON.parse(localStorage.getItem("user"))._id,
};

const formik = useFormik({
  initialValues: initialValues,
  // validationSchema: contactSchema,
  onSubmit: (values, { resetForm }) => {
    AddData();
    resetForm();
  },
});



const {
  errors,
  touched,
  values,
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldValue,
} = formik;




const AddData = async () => {
  try {
    setIsLoding(true);
    let response = await postApi("api/lead/add", values);
    if (response.status === 200) {
      toast.success("Data Submitted Successfully");
      handleCancel();
      dispatch(fetchLeadsAsync())

    }
  } catch (e) {
    console.log(e);

    toast.warning("Data not added ");
  } finally {
    setIsLoding(false);
  }
};

const handleCancel = () => {
  formik.resetForm();
  dispatch(modalClosed());
};



  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getApi("api/user/");
        if (response.status === 200) {
          setUsers(response.data.user);
          console.log(response.data.user, "users's data is here ");
        }
      } catch (error) {
        console.error("Failed to fetch deals", error);
      }
    };
    const fetchSubLocations = async () => {
      try {
        const response = await getApi("api/subLocation/");
        if (response.status === 200) {
          setSubLocations(response.data);
          console.log(response.data, "users's data is here ");
        }
      } catch (error) {
        console.error("Failed to fetch deals", error);
      }
    };
    fetchSubLocations();
    fetchUsers();
  }, []);

  return (
    <form className={cn("grid  items-start gap-4 overflow-auto", className)}>
      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="userId">
          {errors.userId && touched.userId ? errors.userId : "User ID"}
        </Label>
        <select
          name="userId"
          id="userId"
          value={values.userId}
          onChange={(e) => setFieldValue("userId", e.target.value)}
          onBlur={handleBlur}
          className={`border ${
            errors.userId && touched.userId ? "border-red-300" : ""
          }`}
        >
          <option value="" label="Select a user" />
          {users?.map((user) => (
            <option key={user._id} value={user._id} className="text-green-950">
              {user.name}
            </option>
          ))}
        </select>
        {/* <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.userId}
          type="text"
          name="userId"
          id="userId"
          placeholder="Enter user ID"
          className={cn(
            errors.userId && touched.userId ? "border-red-300" : null
          )}
        /> */}
      </div>
      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="name">
          {errors.name && touched.name ? errors.name : "Name"}
        </Label>
        <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          type="text"
          name="name"
          id="name"
          placeholder="Enter name"
          className={cn(errors.name && touched.name ? "border-red-300" : null)}
        />
      </div>

      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="type">
          {errors.type && touched.type ? errors.type : "Lead Type"}
        </Label>
        <select
          name="type"
          id="type"
          value={values.type}
          onChange={(e) => setFieldValue("type", e.target.value)}
          onBlur={handleBlur}
          className={`border border-none ${
            errors.type && touched.type ? "border-red-300" : ""
          }`}
        >
          <option value="" label="Select a Type" />
          <option value="lease" label="Lease" />
          <option value="sale" label="Sale" />
        </select>
      </div>
      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="assignTo">
          {errors.assignTo && touched.assignTo ? errors.assignTo : "Assign To"}
        </Label>
        <select
          name="assignTo"
          id="assignTo"
          value={values.assignTo}
          onChange={(e) => setFieldValue("assignTo", e.target.value)}
          onBlur={handleBlur}
          className={`border ${
            errors.assignTo && touched.assignTo ? "border-red-300" : ""
          }`}
        >
          <option
            value=""
            label="Select an Agent"
            className="max-h-40 overflow-y-scroll"
          />
          {users?.map((user) => (
            <option key={user._id} value={user._id} className="text-green-950 ">
              {user.name}
            </option>
          ))}
        </select>
        {/* <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.assignTo}
          type="text"
          name="assignTo"
          id="assignTo"
          placeholder="Enter user ID"
          className={cn(
            errors.assignTo && touched.assignTo ? "border-red-300" : null
          )}
        /> */}
      </div>

      {/* <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="category">
          {errors.category && touched.category ? errors.category : "Category"}
        </Label>
        <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.category}
          type="text"
          name="category"
          id="category"
          placeholder="Enter category"
          className={cn(
            errors.category && touched.category ? "border-red-300" : null
          )}
        />
      </div> */}
      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="category">
          {errors.category && touched.category
            ? errors.category
            : "Lead category"}
        </Label>
        <select
          name="category"
          id="category"
          value={values.category}
          onChange={(e) => setFieldValue("category", e.target.value)}
          onBlur={handleBlur}
          className={`border border-none ${
            errors.category && touched.category ? "border-red-300" : ""
          }`}
        >
           <option value="" label="Select Category" />
        <option value="premium" label="Premium" />
        <option value="featured" label="Featured" />
        <option value="hot" label="Hot" />
        </select>
      </div>

      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="contactNo">
          {errors.contactNo && touched.contactNo
            ? errors.contactNo
            : "Contact No"}
        </Label>
        <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.contactNo}
          type="text"
          name="contactNo"
          id="contactNo"
          placeholder="Enter contactNo"
          className={cn(
            errors.contactNo && touched.contactNo ? "border-red-300" : null
          )}
        />
      </div>
      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="budget">
          {errors.budget && touched.budget ? errors.budget : "Budget"}
        </Label>
        <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.budget}
          type="text"
          name="budget"
          id="budget"
          placeholder="Enter budget"
          className={cn(
            errors.budget && touched.budget ? "border-red-300" : null
          )}
        />
      </div>

      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="location">
          {errors.location && touched.location ? errors.location : "Location"}
        </Label>
        <select
          name="location"
          id="location"
          value={values.location}
          onChange={(e) => setFieldValue("location", e.target.value)}
          onBlur={handleBlur}
          className={`border border-none ${
            errors.location && touched.location ? "border-red-300" : ""
          }`}
        >
          <option value="" label="Select location" />
          {locations?.map((location) => (
            <option
              key={location._id}
              value={location._id}
              className="text-green-950"
            >
              {location.locationName}
            </option>
          ))}
        </select>
      </div>
      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="subLocation">
          {errors.subLocation && touched.subLocation
            ? errors.subLocation
            : "Sub Location"}
        </Label>
        <select
          name="subLocation"
          id="subLocation"
          value={values.subLocation}
          onChange={(e) => setFieldValue("subLocation", e.target.value)}
          onBlur={handleBlur}
          className={`border border-none ${
            errors.subLocation && touched.subLocation ? "border-red-300" : ""
          }`}
        >
          <option value="" label="Select Sub Location" />
          {subLocations?.map((subLocation) => (
            <option
              key={subLocation._id}
              value={subLocation._id}
              className="text-green-950"
            >
              {subLocation.subLocationName}
            </option>
          ))}
        </select>
      </div>

      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="lookingarea">
          {errors.lookingarea && touched.lookingarea
            ? errors.lookingarea
            : "Looking For"}
        </Label>
        <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lookingarea}
          type="text"
          name="lookingarea"
          id="lookingarea"
          placeholder="Enter Details"
          className={cn(
            errors.lookingarea && touched.lookingarea ? "border-red-300" : null
          )}
        />
      </div>

      <div className="grid border-[1px] p-2 gap-2">
        <Label htmlFor="interestedBeforeDate">
          {errors.interestedBeforeDate && touched.interestedBeforeDate ? errors.interestedBeforeDate : "Interested Before Date"}
        </Label>
    
           <Calendar
          mode="single"
          selected={formik.values.interestedBeforeDate}
          onSelect={(date) => formik.setFieldValue("interestedBeforeDate", date)}
          />
      </div>

      


      <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="remark">
          {errors.remark && touched.remark ? errors.remark : "Remark"}
        </Label>
        <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.remark}
          type="text"
          name="remark"
          id="remark"
          placeholder="Enter remark"
          className={cn(
            errors.remark && touched.remark ? "border-red-300" : null
          )}
        />
      </div>
      {/* <div className="grid border-[1px] p-2  gap-2">
        <Label htmlFor="createBy">
          {errors.createBy && touched.createBy ? errors.createBy : "Create By"}
        </Label>
        <Input
          fontSize="sm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.createBy}
          type="text"
          name="createBy"
          id="createBy"
          placeholder="Enter createBy"
          className={cn(
            errors.createBy && touched.createBy ? "border-red-300" : null
          )}
        />
      </div> */}

    

      <DrawerFooter className="pt-2">
        <DrawerClose asChild>
          <Button onClick={handleSubmit} type="submit">
            Save 
          </Button>
        </DrawerClose>
        <DrawerClose asChild>
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
        </DrawerClose>
      </DrawerFooter>
    </form>
  );
}

function LeadAdd() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(modalData);

  const handleClose = () => {
    dispatch(modalClosed());
  };
  const handleOpen = () => {
    dispatch(modalOpened());
  };

  return (
    <Drawer open={isOpen} direction="right" className="overflow-scroll">
      <DrawerOverlay onClick={handleClose} />
      <DrawerTrigger asChild>
        <Button
          onClick={handleOpen}
          variant="outline"
          className="bg-blue-700 text-white"
        >
          <BiPlus /> Add Lead
        </Button>
      </DrawerTrigger>
      <DrawerContent className="right-0 z-50 left-auto h-full w-[45rem] overflow-auto">
        <div className="mx-auto w-full">
          <IconButton
            className="float-end"
            onClick={handleClose}
            icon={<CloseIcon />}
          />
          <DrawerHeader className="text-left">
            <DrawerTitle>Add Lead</DrawerTitle>
            <DrawerDescription>
              Setup a Lead. Click save when you're done.
            </DrawerDescription>
          </DrawerHeader>
          <ProfileForm className="px-4" />
          {/* <DrawerFooter className="pt-2">
            <DrawerClose asChild>
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
            </DrawerClose>
          </DrawerFooter> */}
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default LeadAdd;
