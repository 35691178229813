import React, { useEffect, useState } from "react";
// import { Bar, BarChart, ResponsiveContainer } from "recharts"
import { fetchLeadsAsync, selectLeads } from "features/Leads/leadSlice.js";
import { selectLocations } from "features/locations/locationSlice.js";
import { selectUsers } from "features/users/userSlice.js";
import { useFormik } from "formik";
import { cn } from "lib/utils.ts";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApi, putApi } from "services/api";
import { Button } from "../button";
import { DrawerClose, DrawerFooter } from "../drawer";
import { Input } from "../input";
import { Label } from "../label";
// import { Bar, BarChart, ResponsiveContainer } from "recharts"






function LeadEdit({ className, leadRowData, setIsModalOpen }) {
  const [isLoding, setIsLoding] = React.useState(false);
  const dispatch = useDispatch();

  const users = useSelector(selectUsers);
  const leads = useSelector(selectLeads);
  const locations = useSelector(selectLocations);

  const [subLocations, setSubLocations] = useState([]);
  const initialValues = {
    _id: leadRowData._id || "",
    name: leadRowData.name || "",
    type: leadRowData.type || "",
    userId: leadRowData.userId || "",
    assignTo: leadRowData.assignTo || "",
    category: leadRowData.category || "",
    contactNo: leadRowData.contactNo || "",
    budget: leadRowData.budget || "",
    location: leadRowData.location || "",
    lookingarea: leadRowData.lookingarea || "",
    nxtreminder: leadRowData.nxtreminder || "",
    subLocation: leadRowData.subLocation || "",
    lookingFor: leadRowData.lookingFor || "",
    interestedBeforeDate: leadRowData.interestedBeforeDate || "",
    action: leadRowData.action || "",
    remark: leadRowData.remark || "",
    createBy: JSON.parse(localStorage.getItem("user"))._id,
};

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: contactSchema,
    onSubmit: (values, { resetForm }) => {
      EditData();
      resetForm();
    },
  });

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  console.log(values);

  const EditData = async () => {
    try {
      setIsLoding(true);
      let response = await putApi(`api/lead/edit/${leadRowData._id}`, values);
      if (response.status === 200) {
        toast.success("Data Edited Successfully");
        handleCancel();
        dispatch(fetchLeadsAsync())
      }
    } catch (e) {
      console.log(e);
      toast.warning("Data not added ");
    } finally {
      setIsLoding(false);
    }
  };


  const handleUnselectImage = (pathToRemove) => {
      //   console.log(formik.initialValues.tenancyContract, "this is picture")

    const updatedContract = formik.values.tenancyContract.filter(
      (item) => item.path !== pathToRemove
    );
  
    formik.setFieldValue("tenancyContract", updatedContract);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    fetch("http://127.0.0.1:5001/api/user/agents")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setAgents(data);
      })
      .catch((error) => {
        console.error("Error fetching agents:", error);
      });
  }, []);
    const fetchSubLocations = async () => {
    try {
      const response = await getApi(
        user.role === "admin"
          ? "api/subLocation/"
          : `api/subLocation/?createdBy=${user._id}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch processed deals");
    }
  };
  useEffect(() => {
    fetchSubLocations().then((data) => {
      setSubLocations(data);
    }
    );

  }, [])

  const handleCancel = () => {
    console.log("sabdjaskdbjasdjkabs")
    formik.resetForm();
    // dispatch(modalClosed());
    setIsModalOpen(false);
  };

  const [dropdownData, setDropdownData] = useState({
    owners: [],
    locations: [],
    subLocations: [],
    projects: [],
  });
  const fetchDropdownData = async () => {
    //create a function to fetch owners data from the backend
    const result = await getApi("api/dropdowns/propertyForm/");
    console.log(result.data);
    setDropdownData(result.data);
  };
  useEffect(() => {
    fetchDropdownData();
  }, []);

  return (
    <form className={cn("grid  items-start gap-4 overflow-auto", className)}>
    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="userId">
        {errors.userId && touched.userId ? errors.userId : "User ID"}
      </Label>
      <select
        name="userId"
        id="userId"
        value={values.userId}
        onChange={(e) => setFieldValue("userId", e.target.value)}
        onBlur={handleBlur}
        className={`border ${
          errors.userId && touched.userId ? "border-red-300" : ""
        }`}
      >
        <option value="" label="Select a user" />
        {users?.map((user) => (
          <option key={user._id} value={user._id} className="text-green-950">
            {user.name}
          </option>
        ))}
      </select>
      {/* <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.userId}
        type="text"
        name="userId"
        id="userId"
        placeholder="Enter user ID"
        className={cn(
          errors.userId && touched.userId ? "border-red-300" : null
        )}
      /> */}
    </div>
    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="name">
        {errors.name && touched.name ? errors.name : "Name"}
      </Label>
      <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.name}
        type="text"
        name="name"
        id="name"
        placeholder="Enter name"
        className={cn(errors.name && touched.name ? "border-red-300" : null)}
      />
    </div>

    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="type">
        {errors.type && touched.type ? errors.type : "Lead Type"}
      </Label>
      <select
        name="type"
        id="type"
        value={values.type}
        onChange={(e) => setFieldValue("type", e.target.value)}
        onBlur={handleBlur}
        className={`border border-none ${
          errors.type && touched.type ? "border-red-300" : ""
        }`}
      >
        <option value="" label="Select a Type" />
        <option value="lease" label="Lease" />
        <option value="sale" label="Sale" />
      </select>
    </div>
    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="assignTo">
        {errors.assignTo && touched.assignTo ? errors.assignTo : "Assign To"}
      </Label>
      <select
        name="assignTo"
        id="assignTo"
        value={values.assignTo}
        onChange={(e) => setFieldValue("assignTo", e.target.value)}
        onBlur={handleBlur}
        className={`border ${
          errors.assignTo && touched.assignTo ? "border-red-300" : ""
        }`}
      >
        <option
          value=""
          label="Select an Agent"
          className="max-h-40 overflow-y-scroll"
        />
        {users?.map((user) => (
          <option key={user._id} value={user._id} className="text-green-950 ">
            {user.name}
          </option>
        ))}
      </select>
      {/* <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.assignTo}
        type="text"
        name="assignTo"
        id="assignTo"
        placeholder="Enter user ID"
        className={cn(
          errors.assignTo && touched.assignTo ? "border-red-300" : null
        )}
      /> */}
    </div>

    {/* <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="category">
        {errors.category && touched.category ? errors.category : "Category"}
      </Label>
      <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.category}
        type="text"
        name="category"
        id="category"
        placeholder="Enter category"
        className={cn(
          errors.category && touched.category ? "border-red-300" : null
        )}
      />
    </div> */}
    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="category">
        {errors.category && touched.category
          ? errors.category
          : "Lead category"}
      </Label>
      <select
        name="category"
        id="category"
        value={values.category}
        onChange={(e) => setFieldValue("category", e.target.value)}
        onBlur={handleBlur}
        className={`border border-none ${
          errors.category && touched.category ? "border-red-300" : ""
        }`}
      >
        <option value="" label="Select Category" />
        <option value="premium" label="Premium" />
        <option value="featured" label="Featured" />
        <option value="hot" label="Hot" />
      </select>
    </div>

    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="contactNo">
        {errors.contactNo && touched.contactNo
          ? errors.contactNo
          : "Contact No"}
      </Label>
      <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.contactNo}
        type="text"
        name="contactNo"
        id="contactNo"
        placeholder="Enter contactNo"
        className={cn(
          errors.contactNo && touched.contactNo ? "border-red-300" : null
        )}
      />
    </div>
    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="budget">
        {errors.budget && touched.budget ? errors.budget : "Budget"}
      </Label>
      <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.budget}
        type="text"
        name="budget"
        id="budget"
        placeholder="Enter budget"
        className={cn(
          errors.budget && touched.budget ? "border-red-300" : null
        )}
      />
    </div>

    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="location">
        {errors.location && touched.location ? errors.location : "Location"}
      </Label>
      <select
        name="location"
        id="location"
        value={values.location}
        onChange={(e) => setFieldValue("location", e.target.value)}
        onBlur={handleBlur}
        className={`border border-none ${
          errors.location && touched.location ? "border-red-300" : ""
        }`}
      >
        <option value="" label="Select location" />
        {locations?.map((location) => (
          <option
            key={location._id}
            value={location._id}
            className="text-green-950"
          >
            {location.locationName}
          </option>
        ))}
      </select>
    </div>
    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="subLocation">
        {errors.subLocation && touched.subLocation
          ? errors.subLocation
          : "Sub Location"}
      </Label>
      <select
        name="subLocation"
        id="subLocation"
        value={values.subLocation}
        onChange={(e) => setFieldValue("subLocation", e.target.value)}
        onBlur={handleBlur}
        className={`border border-none ${
          errors.subLocation && touched.subLocation ? "border-red-300" : ""
        }`}
      >
        <option value="" label="Select Sub Location" />
        {subLocations?.map((subLocation) => (
          <option
            key={subLocation._id}
            value={subLocation._id}
            className="text-green-950"
          >
            {subLocation.subLocationName}
          </option>
        ))}
      </select>
    </div>

    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="lookingarea">
        {errors.lookingarea && touched.lookingarea
          ? errors.lookingarea
          : "Looking For"}
      </Label>
      <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.lookingarea}
        type="text"
        name="lookingarea"
        id="lookingarea"
        placeholder="Enter Details"
        className={cn(
          errors.lookingarea && touched.lookingarea ? "border-red-300" : null
        )}
      />
    </div>

    <div className="grid border-[1px] p-2  relative gap-2">
      <Label htmlFor="interestedBeforeDate">
        {errors.interestedBeforeDate && touched.interestedBeforeDate
          ? errors.interestedBeforeDate
          : "Interested Before Date"}
      </Label>
      <Input
        fontSize="sm"
        // onChange={handleChange}
        onBlur={handleBlur}
        value={values.interestedBeforeDate}
        onChange={(date) => setFieldValue("interestedBeforeDate", date)}
        type="date"
        name="interestedBeforeDate"
        id="interestedBeforeDate"
        placeholder="Enter interestedBeforeDate"
        className={cn(
          errors.interestedBeforeDate && touched.interestedBeforeDate
            ? "border-red-300"
            : null
        )}
      />
      {/* <div className="absolute  z-[100] w-20 top-6 right-40">
        <DatePicker
          name="interestedBeforeDate"
          value={values.interestedBeforeDate}
          onDateChange={(date) => setFieldValue("interestedBeforeDate", date)}
        />
      </div> */}
    </div>
  


    <div className="grid border-[1px] p-2  gap-2">
      <Label htmlFor="remark">
        {errors.remark && touched.remark ? errors.remark : "Remark"}
      </Label>
      <Input
        fontSize="sm"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.remark}
        type="text"
        name="remark"
        id="remark"
        placeholder="Enter remark"
        className={cn(
          errors.remark && touched.remark ? "border-red-300" : null
        )}
      />
    </div>
    

  

    <DrawerFooter className="pt-2">
      <DrawerClose asChild>
        <Button onClick={handleSubmit} type="submit">
          Save 
        </Button>
      </DrawerClose>
      <DrawerClose asChild>
        <Button onClick={handleCancel} variant="outline">
          Cancel
        </Button>
      </DrawerClose>
    </DrawerFooter>
  </form>
  );
}

export default LeadEdit;
