import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteManyApi, getApi } from "services/api";

const initialState = {
  property: [],
  selectedPropertiesId: [],
  status: "idle",
  error: null,
};

const user = JSON.parse(localStorage.getItem("user"));

export const fetchPropertiesAsync = createAsyncThunk(
  "property/fetchProperties",
  async (_, { dispatch }) => {
    try {
      const baseUrl = user.role === "admin"
        ? "api/property/"
        : `api/property/`;
        // : `api/property/?createdBy=${user._id}`;

      // Fetch first 100 properties
      const initialResponse = await getApi(`${baseUrl}?limit=100`);
      console.log(initialResponse.data,"initialResponse")
      // Schedule fetching remaining properties after a delay
      setTimeout(() => {
        dispatch(fetchRemainingPropertiesAsync());
      }, 3000); // 3 seconds delay, adjust as needed

      return initialResponse.data.properties;
    } catch (error) {
      throw new Error("Failed to fetch properties");
    }
  }
);

export const fetchRemainingPropertiesAsync = createAsyncThunk(
  "property/fetchRemainingProperties",
  async () => {
    try {
      const baseUrl = user.role === "admin"
        ? "api/property/"
        : `api/property`;
        // : `api/property/?createdBy=${user._id}`;

      const remainingResponse = await getApi(`${baseUrl}?skip=100&limit=1000000`);
      return remainingResponse.data.properties;
    } catch (error) {
      throw new Error("Failed to fetch remaining properties");
    }
  }
);

export const deletePropertyAsync = createAsyncThunk(
  "property/deleteProperty",
  async (selectedPropertiesId, { getState, rejectWithValue }) => {
    const { property } = getState();

    try {
     
      await deleteManyApi(
        "api/property/deleteMany",
        property.selectedPropertiesId
      );
      return property.selectedPropertiesId;
    } catch (error) {
      return rejectWithValue("Failed to delete propertys");
    }
  }
);

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setPropertyIds: (state, action) => {
      console.log(action.payload, "this is action payload");
      state.selectedPropertiesId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertiesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPropertiesAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.property = action.payload;
      })
      .addCase(fetchPropertiesAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchRemainingPropertiesAsync.fulfilled, (state, action) => {
        state.property = [...state.property, ...action.payload];
      })
      .addCase(deletePropertyAsync.pending, (state) => {
        console.log(state, "this is loading state");
        state.status = "loading";
      })
      .addCase(deletePropertyAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log(action.payload, "payloads");
        state.property = state.property.filter(
          (property) => !action.payload.includes(property._id.toString())
        );
        state.selectedPropertiesId = []; // Reset selection after deletion
      })
      .addCase(deletePropertyAsync.rejected, (state, action) => {
        state.status = "failed";
        console.log(state, "this is failed state");
        state.error = action.payload;
      });
  },
});

export const selectProperty = (state) => state?.property?.property || [];
export const selectStatus = (state) => state.property.status;
export const selectError = (state) => state.property.error;
export const getSelectedpropertiesId = (state) =>
  state.property?.selectedPropertiesId;
export const { setPropertyIds } = propertySlice.actions;

export default propertySlice.reducer;
